import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { useDataModel } from '@thd-nucleus/data-sources';
import { track } from './analytics';
import { categoryDataModel } from './dataModel';
import { VisualNavigationTile } from './VisualNavigationTile';

const PRODUCT_CATALOG_IMAGE_PATH = 'https://images.thdstatic.com/catalog/productImages';

const VisualCategoryNavigation = (props) => {
  const { channel } = useContext(ExperienceContext);
  const {
    keyword,
    navParam,
    data: visNavPropData
  } = props;

  const isControlledData = typeof visNavPropData !== 'undefined' || (!keyword && !navParam);

  const { data, loading, error } = useDataModel('searchModel', {
    variables: {
      keyword,
      navParam
    },
    skip: isControlledData
  });

  const visNavData = visNavPropData || data;
  if (!visNavData || loading || error) {
    return null;
  }

  const { visualNavs, visualNavContainsEvents } = visNavData?.searchModel?.relatedResults || [];
  const { dimensions } = visNavData?.searchModel || [];
  let searchTerm = visNavData?.searchModel?.searchReport?.keyword || '';
  let urlAddOn = null;
  const getUrlParameter = (name) => {
    const param = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + param + '=([^&#]*)');
    let results;
    if (typeof window !== 'undefined') {
      results = regex.exec(window?.location?.search);
    } else {
      results = null;
    }
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };

  const getUrlHashInfo = (hash) => {
    const cleaned = decodeURIComponent(hash);
    const params = cleaned?.split('&');
    const searchTermRaw = params?.find((param) => param?.includes('searchRedirect'));
    return searchTermRaw?.split('=')?.[1] || '';
  };

  if (!searchTerm) {
    searchTerm = getUrlParameter('searchRedirect');
    if (typeof window !== 'undefined') {
      if (!searchTerm && window?.location?.hash) {
        searchTerm = getUrlHashInfo(window?.location?.hash || '');
      }
    }
  }

  if (searchTerm) {
    urlAddOn = 'visNavSearch=' + encodeURIComponent(searchTerm);
  }

  if (!visualNavs?.length) {
    return null;
  }

  const getSpecialValuesRefinement = () => {
    const priceDimension = dimensions?.filter((dimension) => dimension?.label === 'Price')[0];
    return priceDimension?.refinements.find((refinement) => refinement?.refinementKey === '7');
  };

  const addSpecialSavingsToVisNav = (normalizedVisNavs) => {
    const specialValues = getSpecialValuesRefinement();
    if (!specialValues) {
      return normalizedVisNavs;
    }
    const analytics = {
      categoryId: 'savings',
    };
    const specialbuyVisualNav = {
      key: 'specialbuy',
      url: specialValues.url,
      label: 'Shop Savings',
      analytics,
      src: 'https://assets.thdstatic.com/images/special-buy--pod.jpg'
    };
    normalizedVisNavs.push(specialbuyVisualNav);
    return normalizedVisNavs;
  };

  const getVisNav = (isMobileSlider) => {
    const size = (isMobileSlider) ? 145 : 300;
    const classes = classNames(
      'vis-nav__item',
      'first:sui-ml-1',
      'last:sui-mr-1',
      'sui-basis-1/8',
      'sui-max-w-xs',
      'sui-p-2',
      'sui-bg-primary',
      'sui-mx-4',
      { 'sui-min-w-8': isMobileSlider }
    );
    let normalizedVisNavs = [];
    visualNavs.forEach((visualNav, index) => {
      const {
        label, imageId, webUrl, categoryId, imageURL = ''
      } = visualNav;
      const indexedImageId = imageId + index;
      const imgUrl = imageId
        ? `${PRODUCT_CATALOG_IMAGE_PATH}/${size}/${imageId.substring(0, 2)}/${imageId}_${size}.jpg`
        : imageURL;
      const productUrl = `${webUrl}`;
      const analytics = {
        categoryId,
      };
      normalizedVisNavs.push({
        key: indexedImageId,
        url: productUrl,
        label,
        analytics,
        src: imgUrl
      });
    });
    if (!visualNavContainsEvents) {
      normalizedVisNavs = addSpecialSavingsToVisNav(normalizedVisNavs);
    }
    return normalizedVisNavs.map((visNav, index) => {
      const {
        key,
        url,
        label,
        analytics,
        src
      } = visNav;
      const indexedKey = key + index;
      let newUrl = url;
      if (urlAddOn) {
        newUrl = url.includes('?')
          ? url + '&' + urlAddOn
          : url + '?' + urlAddOn;
      }
      return (
        <VisualNavigationTile
          key={indexedKey}
          classes={classes}
          src={src}
          href={newUrl}
          onClick={() => track({ ...analytics, position: index + 1 })}
          label={label}
        />
      );
    });
  };

  const isMobileSlider = (channel === 'mobile');
  const getVisNavFunc = getVisNav(isMobileSlider);
  const spread = (visualNavs.length > 3 && !isMobileSlider);
  const spreadScroll = (visualNavs.length > 2 && !isMobileSlider);
  const spreadfullwidth = (spread) ? 'space-between' : '';

  const fullwidthMobileRespFull = (spreadScroll) ? 'scroll' : '';
  return (
    <div
      data-testid="visual-category-navigation"
      className="visual-navigation"
      data-component="VisualCategoryNavigation"
    >
      <div className={classNames(
        'vis-nav__collection',
        'sui-flex',
        'sui-flex-nowrap',
        'sui-items-stretch',
        {

          'sui-justify-start': isMobileSlider,
          'sui-h-44': isMobileSlider,
          'sui-justify-between': spreadfullwidth,
          'sui-overflow-y-auto': fullwidthMobileRespFull
        }
      )}
      >
        {getVisNavFunc}
      </div>
    </div>
  );

};

VisualCategoryNavigation.dataModel = categoryDataModel;

VisualCategoryNavigation.propTypes = {
  keyword: PropTypes.string,
  navParam: PropTypes.string,
  data: PropTypes.shape({}),
};

VisualCategoryNavigation.defaultProps = {
  keyword: '',
  navParam: '',
  data: undefined,

};

export { VisualCategoryNavigation };
